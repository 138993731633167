import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "What to do if the WebUI malfunctions?",
  "path": "/Frequently_Asked_Question/Browser_Developer_Console/",
  "dateChanged": "2021-07-08",
  "author": "Mike Polinowski",
  "excerpt": "If the camera's WebUI starts to behave strangely in all your browsers - for example after an update - you should take a look at the browser developer console.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='What to do if the WebUI "malfunctions"?' dateChanged='2021-07-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='If the camera`s WebUI starts to behave strangely in all your browsers - for example after an update - you should take a look at the browser developer console.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationDE={props.location.pathname} locationEN='/en/Frequently_Asked_Question/Browser_Developer_Console/' locationFR='/fr/Frequently_Asked_Question/Browser_Developer_Console/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "troubleshooting-in-the-web-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#troubleshooting-in-the-web-interface",
        "aria-label": "troubleshooting in the web interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Troubleshooting in the Web Interface`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: What to do if the WebUI "malfunctions"?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: If the camera\`s WebUI starts to behave strangely in all your browsers - for example after an update:`}</p>
    <ul>
      <li parentName="ul">{`Live video no longer plays.`}</li>
      <li parentName="ul">{`Menus can no longer be opened.`}</li>
      <li parentName="ul">{`Buttons / sliders can be used but settings are not saved.`}</li>
    </ul>
    <p>{`etc.`}</p>
    <p>{`You should take a look at the browser developer console. The console can be opened either by pressing the `}<strong parentName="p">{`F12`}</strong>{` key or by right-clicking and selecting `}<strong parentName="p">{`Inspect`}</strong>{` from the context menu:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8ed791a3b475b31171245fcc5f9c2336/d74fe/Entwickler_Console_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABtklEQVQY0wGrAVT+ADtFRDhDQTk8PDo7PD0+Pzc3OTc4Ojc4OTQ1NjU2NzU1NzU1NzU1NzY3ODs6O0Q+PkpFR0NAPz49Oj5AOwBqQE5uPk5WTU5SUU9XVFJYVVJQTUxRTk5TT05DQUI2NzovMTMuLzE2NjkyNjgyUlBIX2pKYGxJX2tMYm4A3xpe8hZigGZxbXx4eoF7W2NiO0JJSlBTTlRZOj5CMjM3KisvJygrKyktKjY4BpWNbcPud8f2gMnxdcbyAME+a9E9b5VXU5tyX6V7XJRrUHJGOnlNP3ZGOVU6MzE1ODAxNC8wMywrLicwMQ5xa2O03Wu55m644Wu44wCNkZCnqKT1YzP/URj+VR7/Vx//WCD8ViD/Wh6ySScvNDk+PD0zMjQuLC8pKCoZGxxnsdprvepktuNluOYAnZaZn5SW5Vgs/1cg+1cj+1Yi+1Yi+FUi/1khp0QlJjY/NjxBIyctISowKCgrHiYmZbLdZ7zraLfjabnmAKelp1lXXNxQJP9XIf1WIf5WIf5WIfxWIf9XIMpNJ3FIPIZBM7cxJXo+MDg4OD5AQK/AyrPK12W14WK35SFWn5i1op/XAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ed791a3b475b31171245fcc5f9c2336/e4706/Entwickler_Console_01.avif 230w", "/en/static/8ed791a3b475b31171245fcc5f9c2336/d1af7/Entwickler_Console_01.avif 460w", "/en/static/8ed791a3b475b31171245fcc5f9c2336/7f308/Entwickler_Console_01.avif 920w", "/en/static/8ed791a3b475b31171245fcc5f9c2336/db27b/Entwickler_Console_01.avif 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8ed791a3b475b31171245fcc5f9c2336/a0b58/Entwickler_Console_01.webp 230w", "/en/static/8ed791a3b475b31171245fcc5f9c2336/bc10c/Entwickler_Console_01.webp 460w", "/en/static/8ed791a3b475b31171245fcc5f9c2336/966d8/Entwickler_Console_01.webp 920w", "/en/static/8ed791a3b475b31171245fcc5f9c2336/4b3db/Entwickler_Console_01.webp 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ed791a3b475b31171245fcc5f9c2336/81c8e/Entwickler_Console_01.png 230w", "/en/static/8ed791a3b475b31171245fcc5f9c2336/08a84/Entwickler_Console_01.png 460w", "/en/static/8ed791a3b475b31171245fcc5f9c2336/c0255/Entwickler_Console_01.png 920w", "/en/static/8ed791a3b475b31171245fcc5f9c2336/d74fe/Entwickler_Console_01.png 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8ed791a3b475b31171245fcc5f9c2336/c0255/Entwickler_Console_01.png",
              "alt": "Was tun wenn die WebUI “spinnt”?",
              "title": "Was tun wenn die WebUI “spinnt”?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then go to the `}<strong parentName="p">{`Console`}</strong>{` tab and you will often already see error messages that can help you to solve the problem:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6c6680f071b2f3083dcb8da8267ab2ff/cdef6/Entwickler_Console_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.782608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA/ElEQVQY04XEu07CUBgA4M4GowMwqCecS2+HlnP7e05bLFoMpI0xTrCqiW6oxLDopptv5uriS/gUBgdXky+f58oKbJEKzRPhiskwBT8YUhaFXIWxiLgIuQxikYgsg7yu54vFTT07z06mMKk8oUGDNcZKBaXl1Wn6rOL3kEIZ5Ibl4DvDckOtJiBxYQNr8LjgGcQWIu9y3lzMmqZp2radjtHZcXdpD5ZpP1Mdp7pO9Zzuw2hPJR2d7OrfJd8xo32ret4XvvqQt+vH1WazNgYQGiBKDwnBmGFMMSZbhGHCyB+6RanvfaO7T//66eH+9e1FSnWEKKEhIQH+z4D4P8ZoOnxNbJOQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c6680f071b2f3083dcb8da8267ab2ff/e4706/Entwickler_Console_02.avif 230w", "/en/static/6c6680f071b2f3083dcb8da8267ab2ff/d1af7/Entwickler_Console_02.avif 460w", "/en/static/6c6680f071b2f3083dcb8da8267ab2ff/7f308/Entwickler_Console_02.avif 920w", "/en/static/6c6680f071b2f3083dcb8da8267ab2ff/63701/Entwickler_Console_02.avif 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6c6680f071b2f3083dcb8da8267ab2ff/a0b58/Entwickler_Console_02.webp 230w", "/en/static/6c6680f071b2f3083dcb8da8267ab2ff/bc10c/Entwickler_Console_02.webp 460w", "/en/static/6c6680f071b2f3083dcb8da8267ab2ff/966d8/Entwickler_Console_02.webp 920w", "/en/static/6c6680f071b2f3083dcb8da8267ab2ff/5231b/Entwickler_Console_02.webp 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c6680f071b2f3083dcb8da8267ab2ff/81c8e/Entwickler_Console_02.png 230w", "/en/static/6c6680f071b2f3083dcb8da8267ab2ff/08a84/Entwickler_Console_02.png 460w", "/en/static/6c6680f071b2f3083dcb8da8267ab2ff/c0255/Entwickler_Console_02.png 920w", "/en/static/6c6680f071b2f3083dcb8da8267ab2ff/cdef6/Entwickler_Console_02.png 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6c6680f071b2f3083dcb8da8267ab2ff/c0255/Entwickler_Console_02.png",
              "alt": "Was tun wenn die WebUI “spinnt”?",
              "title": "Was tun wenn die WebUI “spinnt”?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To get more information you can switch to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`main(admin.html)`}</code>{` here:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5a344c60a4829cee558eed9fb17bacb6/1320e/Entwickler_Console_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABjElEQVQY013LXS/zUAAA4P4Hu9CNntOe9pz29LRb7aPLZquwsPlMNiyERFy875tdvIJL4UbC1MYSErIJZiEZIUZ2gx/AvyLCBclz+3B+M2AYfk3VDGYwXZdEBAQgK1TTLQWrSMZUN1WNEc1UiEGoZVkRJ5kaG5/t7RvkooPZeGbKGc4n0lPJzLQzMp3I5BNOyIn5I0FbValKmYL1cJDEwiBi+YJ+bzQk2D1dcRtxK9utpWLrb/m+sNcq7D/8cW//7bczk9kAgyE7QVQdE6oQZlDB1DyMeJja8cmjkw6utnpfWn8sHD8tV18KB+3ZzeZi+dFOz8hQjNlRjBCCEEsiL2MeyBAqACpA/MK5peedUnur0tw5vCkeNNe365t7zdRQDtKewEAWBPu9ZtIXcMYxCwGpS5RFUYbfuLWrt/L5y2Xx6MKtNdxqw61eV+q59ARvxKP5/yS9AFLzIDW3JhujQOr8lTcarxuVuwu3erZ7crZ7curWLiv17HDOy/Mm1bEkoe5uJAi8iHw/50d+B3mqdYGXs+rgAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a344c60a4829cee558eed9fb17bacb6/e4706/Entwickler_Console_03.avif 230w", "/en/static/5a344c60a4829cee558eed9fb17bacb6/d1af7/Entwickler_Console_03.avif 460w", "/en/static/5a344c60a4829cee558eed9fb17bacb6/7f308/Entwickler_Console_03.avif 920w", "/en/static/5a344c60a4829cee558eed9fb17bacb6/0e2fe/Entwickler_Console_03.avif 1292w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5a344c60a4829cee558eed9fb17bacb6/a0b58/Entwickler_Console_03.webp 230w", "/en/static/5a344c60a4829cee558eed9fb17bacb6/bc10c/Entwickler_Console_03.webp 460w", "/en/static/5a344c60a4829cee558eed9fb17bacb6/966d8/Entwickler_Console_03.webp 920w", "/en/static/5a344c60a4829cee558eed9fb17bacb6/e3770/Entwickler_Console_03.webp 1292w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a344c60a4829cee558eed9fb17bacb6/81c8e/Entwickler_Console_03.png 230w", "/en/static/5a344c60a4829cee558eed9fb17bacb6/08a84/Entwickler_Console_03.png 460w", "/en/static/5a344c60a4829cee558eed9fb17bacb6/c0255/Entwickler_Console_03.png 920w", "/en/static/5a344c60a4829cee558eed9fb17bacb6/1320e/Entwickler_Console_03.png 1292w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5a344c60a4829cee558eed9fb17bacb6/c0255/Entwickler_Console_03.png",
              "alt": "Was tun wenn die WebUI “spinnt”?",
              "title": "Was tun wenn die WebUI “spinnt”?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And read out the following variables (just type in the following commands inside the console and press enter):`}</p>
    <ol>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar.config.protocol`}</code></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar.system`}</code></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar.video`}</code></li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/72be3cac4de1d98bafbb8721df06d4cb/d0fa6/Entwickler_Console_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACSElEQVQoz32QS2vUUBxH8xHUik47dHJfyb1JbpLJTJpJMpm0k0zfM7Z2pm36stWqYCnUXamgIIoL7caFG4W24M6PoCu3YkHoQhd+BpdioRDJdFwpXg4/zubwhyvovGgaJa4XdaNkmCWmcEIYVbhh2gYvIiRruqVqpsaLimaqvGgW7YrjRfF0PRoXioFnBb7nV13Pdz0/CGq+H3oeb4QDzQiM+rnIuxI4/bVKPqwM+KU+x7zgWpdc66Jr9QkvKwvPh5O11aXNjbXbN9c7szOz12bW4nDTljcsUJPzBF9lJEdJf5fcn83JOCf8uLz+Fd3a2t3de/Jo58F9L54cmZ6dCKIpXqqZNtJLkjGEuQVUA2pmtl2BmgEVXXi3/fTD9u7xinNyJ/i2FXxsm5875l6VFvSKarqQKphpiKpAZpApUGaZUAXKCqRMeHVw9P71ftrOp51C2h48WxDTZfFoGAxyh6tDoogAwBmQAIgBIJkAci7C4cHbxysLv+bE00V8uoh/zqM0QW9CNMhdTbFFgLrZvxEePtvfmW6kCUgTmCbgLIHpCjisiQXD5dwpiFAE+G968Y3Ne3ebYycdfjyFvrTIp0n4fU5+0TD6jQq3fIwpIgxhignF3UWYIiT34marHY7EssIH8mLZ9kYbk535petjE7FmeUZZZlqWEUYkdi6YMERYL26MtxzH01Sua7zVnFlOVqt+YFKlWnbLpo0llWSlKkkqliiWKJIZoRrMjmMhqtfjOB7tviiqh2Gt0Yht24HGkGrZEJH/fNhvts6lvmeScnMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/72be3cac4de1d98bafbb8721df06d4cb/e4706/Entwickler_Console_04.avif 230w", "/en/static/72be3cac4de1d98bafbb8721df06d4cb/d1af7/Entwickler_Console_04.avif 460w", "/en/static/72be3cac4de1d98bafbb8721df06d4cb/7f308/Entwickler_Console_04.avif 920w", "/en/static/72be3cac4de1d98bafbb8721df06d4cb/216d5/Entwickler_Console_04.avif 1263w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/72be3cac4de1d98bafbb8721df06d4cb/a0b58/Entwickler_Console_04.webp 230w", "/en/static/72be3cac4de1d98bafbb8721df06d4cb/bc10c/Entwickler_Console_04.webp 460w", "/en/static/72be3cac4de1d98bafbb8721df06d4cb/966d8/Entwickler_Console_04.webp 920w", "/en/static/72be3cac4de1d98bafbb8721df06d4cb/879a2/Entwickler_Console_04.webp 1263w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/72be3cac4de1d98bafbb8721df06d4cb/81c8e/Entwickler_Console_04.png 230w", "/en/static/72be3cac4de1d98bafbb8721df06d4cb/08a84/Entwickler_Console_04.png 460w", "/en/static/72be3cac4de1d98bafbb8721df06d4cb/c0255/Entwickler_Console_04.png 920w", "/en/static/72be3cac4de1d98bafbb8721df06d4cb/d0fa6/Entwickler_Console_04.png 1263w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/72be3cac4de1d98bafbb8721df06d4cb/c0255/Entwickler_Console_04.png",
              "alt": "Was tun wenn die WebUI “spinnt”?",
              "title": "Was tun wenn die WebUI “spinnt”?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If nothing jumps out at you here that could possibly explain the problem (`}<strong parentName="p">{`even in different browsers and on different systems`}</strong>{`), then the best thing to do is to reset the camera via the WebUI (while retaining the network configuration) and reapply the last firmware update.`}</p>
    <p>{`If the problem still exists after this, you can pass on the information obtained from the browser console to the support team.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      